





















































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { MedicaoService } from '@/core/services/residuo';
import { Medicao} from '@/core/models/residuo';
import { PageBase } from '@/core/models/shared';
import { ClienteService, EmpresaService } from '@/core/services/geral';
import { Cliente, Empresa } from '@/core/models/geral';

@Component
export default class ListaMedicao extends PageBase {
  search: any = '';
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '',value:"actions" ,sortable: false },
    { text: 'Numero', value: 'numero', type:'number' },
    { text: 'Data', value: 'data' },
    { text: 'Cliente', value: 'cliente.nome' },
  ];
  item = new Medicao();
  service = new MedicaoService();

  clienteService = new ClienteService();
  clientes: Cliente[] = [];
  isClienteLoading: boolean = false;
  onSearchCliente: any = null;

  empresa: Empresa = new Empresa();
  empresaService: EmpresaService = new EmpresaService();

  sheet: boolean = false;
  filtro: any = {
    numero: null,
    clienteId: this.app.clienteId,
    dataInicial: '',
    dataFinal: '',
  }
  relatorioDialog: boolean = false;
  routeDetalhar: string = 'detalharMedicao';
  idDetalhar: number = 0;
  excelDialog: boolean = false;
  excelOpcao: string = 'padrao';

  @Watch('onSearchCliente')
  SearchCliente (val: string) {
    if (this.filtro.clienteId) return;
    if (this.isClienteLoading) return;
    if (!val) return;

    this.isClienteLoading = true
    this.clienteService.AutoComplete(val).then(
      res => {
        this.clientes = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    ).finally(() => (this.isClienteLoading = false));
  }

  @Watch('options', { deep: true })
  Atualizar(isFiltro: boolean = false){

    if (isFiltro == true) {
      this.options.page = 1;
    }

    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
    this.sheet = false;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.service.MontaFiltro(this.filtro), "Cliente").then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
    .finally(() => (this.loading = false));
  }

  AbrirDialogCadastro(item: Medicao){
    if(item){
      this.service.ObterPorId(item.id, "Cliente, OrdemServicos.OrdemServico.Base, OrdemServicos.OrdemServico.Porto, OrdemServicos.OrdemServico.Embarcacao, OrdemServicos.OrdemServico.Contrato, OrdemServicos.OrdemServico.Residuos.Residuo, OrdemServicos.OrdemServico.Servicos.Servico, Locacoes.OrdemServicoLocacao.Tipo, Locacoes.OrdemServicoLocacao.OrdemServico, Locacoes.OrdemServicoLocacao.Parent.Equipamento, Locacoes.OrdemServicoLocacao.Equipamento, Locacoes.OrdemServicoLocacao.EquipamentoTroca").then(
        res => {
          this.item = new Medicao(res.data);
          this.dialogCadastro = true;
        },
        err => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
    }
    else{
      this.item = new Medicao();
      this.item.empresaId = this.app.empresaId;
      this.empresaService.GetConfiguracao(this.app.empresaId).then(res => {
        this.item.observacao = res.data.observacaoMedicao;
      })
      this.dialogCadastro = true;
    }
  }

  Excluir(item: Medicao){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          return this.service.Excluir(item.id)
                  .then(res => {
                      if (res.status == 200){
                          return res.data;
                      }
                  },
                  err => {
                    if (err.response.status == 403){
                      this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
                    }
                    else{
                      this.$swal('Aviso', err.response.data, 'error')
                    }
                  }
              )
          },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", result.value, "success");
              this.Atualizar();
          }
      })
  }

  

  AbrirJanelaDetalhar(id: number, rota?: string) {

    if(id > 0){
      this.idDetalhar = id
    }

    if(rota){
      let routeLink = this.$router.resolve({ name: rota, params: { id: this.idDetalhar.toString() } });
      window.open(routeLink.href, '_blank');
    }
    else{
      this.relatorioDialog = true;
    }    
  }

  AbrirJanelaExcel(){
    this.excelDialog = true;
  }

  GerarExcel(opcao: string) {

    if(opcao === "padrao") {
      this.ExportarExcel('tabela');
    }
    else {
      this.service.ExcelBruto(0, -1, [], [], null, [], this.filtro).then(
        res => {
          this.JsonToCSV(res.data.items, "Medições", true);
        },
        err=>{
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
    }
    this.excelDialog = false;
  }

  Imprimir(){
    let routeLink = this.$router.resolve({name: "relatorioMedicao",
      query:{
        numero: this.filtro.numero,
        clienteId: this.filtro.clienteId,
        dataInicial: this.filtro.dataInicial,
        dataFinal: this.filtro.dataFinal,
      }
    });

    window.open(routeLink.href, '_blank');
  }
}
